<template>
    <div class="customer">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>渠道筛选</span>
                    <el-input
                        size="small"
                        style="width: 215px"
                        placeholder="请输入渠道名称"
                        v-model.trim="nameVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="(pagesize = 20), (currentPage = 1), getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        style="border-color: #2370eb"
                    ></el-button>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="(pagesize = 20), (currentPage = 1), getData()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 12px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
        </div>
        <div class="table-title">
            <div class="status_btn">报备渠道列表</div>
            <span
                ><button
                    class="add"
                    @click="dialogAdd = true"
                    style="cursor: pointer"
                >
                    <i class="el-icon-plus"></i> 新增渠道
                </button></span
            >
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column
                label="渠道名称"
                prop="channelName"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onUpData(scope.row)"
                        >{{ scope.row.channelName }}</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column
                label="所在地"
                prop="departmentName"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <p>
                        {{
                            $address.address(
                                scope.row.provinceId,
                                scope.row.cityId
                            )
                        }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column label="渠道状态" show-overflow-tooltip width="110">
                <template slot-scope="scope"
                    ><el-switch
                        v-model="scope.row.isStatus"
                        @change="onStatus(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增 -->
        <el-dialog
            :visible.sync="dialogAdd"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增渠道
                </div>
            </template>
            <div>
                <Add ref="add" @close="handleClose" />
            </div>
        </el-dialog>
        <!-- 编辑 -->
        <el-dialog
            :visible.sync="dialogUpData"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    编辑渠道
                </div>
            </template>
            <div>
                <UpData ref="upData" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    channeManagelList,
    channeManagelUpdate,
} from '@/api/reportchannel/reportchannel';
import Add from './add.vue';
import UpData from './upData.vue';
export default {
    components: {
        Add,
        UpData,
    },
    data() {
        return {
            nameVal: '',
            value1: true,
            tableData: [{}],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            btnP: {},
            dialogAdd: false,
            dialogUpData: false,
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            this.getData();
        },

        getData() {
            let data = {
                param: {
                    name: this.nameVal,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };

            channeManagelList(data).then((res) => {
                console.log(res);
                this.total = res.data.total;
                for (let i = 0; i < res.data.list.length; i++) {
                    if (res.data.list[i].status == 1) {
                        res.data.list[i].isStatus = true;
                    } else {
                        res.data.list[i].isStatus = false;
                    }
                }
                this.tableData = res.data.list;
            });
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        onStatus(row) {
            console.log(row.isStatus);
            let data = {
                param: {
                    id: row.id,
                },
            };
            if (!row.isStatus) {
                data.param.status = 2;
            } else {
                data.param.status = 1;
            }
            channeManagelUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                }
            });
        },
        onUpData(row) {
            this.dialogUpData = true;
            setTimeout(() => {
                this.$refs.upData.getData(row);
            });
        },
        handleClose() {
            this.dialogAdd = false;
            this.dialogUpData = false;
            this.getData();
        },
        searchEmpty() {
            this.nameVal = '';
            this.pagesize = 20;
            this.currentPage = 1;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
    }
    .add {
        border-color: #d9d9d9;
        color: #333333;
        .icon-faqibaobei {
            font-size: 12px;
            margin-right: 8px;
        }
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }

    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .status_btn {
            font-weight: 600;
            font-size: 14px;
            color: #333333;
        }
        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            color: #333;
            margin-left: 16px;
            text-align: center;
        }
        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
    }
}
</style>
