<template>
    <div class="adjust">
        <div class="content">
            <el-form
                ref="form"
                style="flex:1;margin-top:20px;margin-left: 10px;margin-bottom:50px;"
                label-position="left"
                :model="form"
                label-width="100px"
            >
                <el-form-item label="渠道名称" required>
                    <el-input
                        v-model="form.channelName"
                        size="small"
                        placeholder="请输入渠道名称"
                        clearable
                        style="width:270px;"
                    ></el-input>
                </el-form-item>
                <el-form-item label="地区" required>
                    <el-cascader
                        size="small"
                        style="width:270px;"
                        clearable
                        :options="$address.cascader()"
                        v-model="addressArr"
                        placeholder="请选择地区"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="启用状态" required>
                    <el-radio v-model="form.status" :label="1">启用</el-radio>
                    <el-radio v-model="form.status" :label="2">停用</el-radio>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { channeManagelUpdate } from '@/api/reportchannel/reportchannel';
export default {
    data() {
        return {
            form: {
                id: '',
                channelName: '',
                status: 0,
            },
            addressArr: [],
        };
    },
    methods: {
        getData(row) {
            this.form.id = row.id;
            this.form.channelName = row.channelName;
            this.form.status = row.status;
            if (row.cityId == 0) {
                this.addressArr = [String(row.provinceId)];
            } else {
                this.addressArr = [String(row.provinceId), String(row.cityId)];
            }
        },
        onSubmit() {
            let data = {
                param: this.form,
            };
            if (!this.form.channelName) {
                return this.$message.error('请输入渠道名称');
            }

            if (!this.addressArr || this.addressArr.length == 0) {
                return this.$message.error('请选择地区');
            }
            if (!this.form.status) {
                return this.$message.error('请选择启用状态');
            }
            if (this.addressArr.length == 1) {
                this.addressArr.push('0');
            }
            data.param.provinceId = this.addressArr[0];
            data.param.cityId = this.addressArr[1];

            channeManagelUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.addressArr = [];
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.adjust {
    height: 100%;
    .content {
        padding-left: 79px;
        padding-bottom: 180px;
        p {
            padding-top: 16px;
            span:nth-child(1) {
                width: 160px;
                display: inline-block;
                color: #333333;
                font-size: 14px;
            }
            span:nth-child(2) {
                color: #2370eb;
            }
        }
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>
